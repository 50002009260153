<template>
  <vx-card title="DN Claim">
    <vs-button class="mb-2" @click="handleOpenForm">Create</vs-button>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-tabs v-model="tab">
          <vs-tab label="Open" >
            <open :key="componentKey"></open>
          </vs-tab>
          <vs-tab label="Pending" :focus="true">
            <div class="tab-text">
              <pending></pending>
            </div>
          </vs-tab>
          <vs-tab label="Rejected">
            <div class="tab-text">
              <reject></reject>
            </div>
          </vs-tab>
          <vs-tab label="Approved">
            <div class="tab-text">
              <approved :debitNoteCode="debitNoteCode" @reset-dn-code-params="handleResetDnCode"></approved>
            </div>
          </vs-tab>
          <vs-tab label="Applied">
            <div class="tab-text">
              <applied></applied>
            </div>
          </vs-tab>
          <vs-tab label="Paid">
            <div class="tab-text">
              <paid></paid>
            </div>
          </vs-tab>
          <vs-tab label="Waiting Approval Reversal">
            <div class="tab-text">
              <waiting-revers></waiting-revers>
            </div>
          </vs-tab>
          <vs-tab label="Reversed">
            <div class="tab-text">
              <reversed></reversed>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <form-create @close="handleClose"> </form-create>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import Open from "./open";
import Pending from "./pending";
import Reject from "./reject";
import Approved from "./approved";
import Applied from "./applied";
import FormCreate from "./form.vue";
import Reversed from "./reversed";
import Paid from "./paid";
import WaitingRevers from "./waiting-revers";
export default {
  components: {
    Open,
    Pending,
    Approved,
    Applied,
    Reject,
    Reversed,
    Paid,
    WaitingRevers,
    FormCreate,
  },
  data() {
    const code =this.$route.params.code
    const status =this.$route.params.status
    const tabStsPosition=[
        1,//0(Pending)=>tab1
        3,//1(approved)=>tab3
        2,//2(rejected)=>tab2
        4,//3(applied)=>tab4
        6,//4(reverse)=>tab6
        0,//5(waiting reverse)=>?
        0 //6(waiting reverse to open)=>?
      ]
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      tab:code&&status&&parseInt(status)<7&&parseInt(status)>=0?tabStsPosition[parseInt(status)]:0,
      dn_id: "",
      debitNoteCode: code,
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      componentKey: 0,
    };
  },
  mounted () {
    
  },
  methods: {
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
    handleResetDnCode(){
      this.debitNoteCode =""
    }
  },
};
</script>
<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>